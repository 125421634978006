import { FunctionComponent, useMemo, type CSSProperties } from "react";

type HeaderType = {
  title?: string;
  actionlose?: string;

  /** Style props */
  typeSecondaryContentTitlePadding?: CSSProperties["padding"];
  typeSecondaryContentTitleHeight?: CSSProperties["height"];
  titleFontSize?: CSSProperties["fontSize"];
  titleLineHeight?: CSSProperties["lineHeight"];
  buttonsCompactHeight?: CSSProperties["height"];
  buttonsCompactPadding?: CSSProperties["padding"];
  actionloseIconWidth?: CSSProperties["width"];
  actionloseIconHeight?: CSSProperties["height"];

  onClose?: () => void;
};

const Header: FunctionComponent<HeaderType> = ({
  title,
  actionlose,
  typeSecondaryContentTitlePadding,
  typeSecondaryContentTitleHeight,
  titleFontSize,
  titleLineHeight,
  buttonsCompactHeight,
  buttonsCompactPadding,
  actionloseIconWidth,
  actionloseIconHeight,
  onClose,
}) => {
  const typeSecondaryContentTitleLStyle: CSSProperties = useMemo(() => {
    return {
      padding: typeSecondaryContentTitlePadding,
      height: typeSecondaryContentTitleHeight,
    };
  }, [typeSecondaryContentTitlePadding, typeSecondaryContentTitleHeight]);

  const titleStyle: CSSProperties = useMemo(() => {
    return {
      fontSize: titleFontSize,
      lineHeight: titleLineHeight,
    };
  }, [titleFontSize, titleLineHeight]);

  const buttonsCompactStyle: CSSProperties = useMemo(() => {
    return {
      height: buttonsCompactHeight,
      padding: buttonsCompactPadding,
    };
  }, [buttonsCompactHeight, buttonsCompactPadding]);

  const actionloseIconStyle: CSSProperties = useMemo(() => {
    return {
      width: actionloseIconWidth,
      height: actionloseIconHeight,
    };
  }, [actionloseIconWidth, actionloseIconHeight]);

  return (
    <div
      className="bg-bg-primary box-border flex flex-row items-center justify-start pt-2.5 pb-2 pr-3 pl-5 text-left text-base text-text-primary font-body-12px-16lh-medium self-stretch border-b-[1px] border-solid border-border-tertiary rounded-t-[16px]"
      style={typeSecondaryContentTitleLStyle}
    >
      <div
        className="flex-1 relative leading-[24px] font-semibold"
        style={titleStyle}
      >
        {title}
      </div>
      <div
        className="cursor-pointer rounded-buttons-fields h-6 flex flex-row flex-wrap items-center justify-start p-1 box-border"
        style={buttonsCompactStyle}
        onClick={onClose}
      >
        <span className="relative w-4 h-4 object-cover">
          <svg
            xmlns="http://www.w3.org/2000/svg"
            width="17"
            height="17"
            viewBox="0 0 17 17"
            fill="none"
          >
            <path
              d="M15.8337 15.8337L4.16699 4.16699M15.8337 4.16699L4.16699 15.8337"
              stroke="#9D9DAC"
              stroke-linecap="round"
            />
          </svg>
        </span>
      </div>
    </div>
  );
};

export default Header;
