import axios from "axios";
import { IMessage, IRegisterUser } from "../types/model";

const baseUrl = process.env.REACT_APP_URL || 'https://chat-api.test-team-flame.ru'

export const register = async (): Promise<IRegisterUser | null> => {
  try {
    const user = await axios.post(`${baseUrl}/auth/register`);
    return user.data;
  } catch (error) {
    console.log(error);
  }
  return null;
};

export const createChat = async (token: string): Promise<{ _id: string }> => {
  try {
    const createChat = await axios.post(
      `${baseUrl}/chats/create-chat`,
      {
        members: [process.env.REACT_APP_SUPPORT_ID || '6570a25a16a8896805b2121e'],
      },
      {
        headers: {
          accept: "application/json",
          "Content-Type": "application/json",
          authorization: `Bearer ${token}` || "",
          'X-Api-Version': '1',
        },
      }
    );
    return createChat.data;
  } catch (error) {
    console.log(error);
  }
  return { _id: "" };
};

export const getMe = async (token: string): Promise<{ id: string }> => {
  try {
    const user = await axios.get(`${baseUrl}/auth/me`, {
      headers: {
        authorization: `Bearer ${token}` || "",
        'X-Api-Version': '1',
      },
    });
    return user.data;
  } catch (error) {
    console.log(error);
  }
  return { id: "" };
};

export const getChat = async (token: string): Promise<{ _id: string }> => {
  try {
    const chats = await axios.get(`${baseUrl}/chats`, {
      headers: {
        authorization: `Bearer ${token}` || "",
        'X-Api-Version': '1',
      },
    });
    const chat = chats.data.find((item: any) =>
      item.members.includes(process.env.REACT_APP_SUPPORT_ID || '6570a25a16a8896805b2121e')
    );
    if (!chat) {
      return await createChat(token);
    }
    return { _id: chat._id || "" };
  } catch (error) {
    console.log(error);
  }
  return { _id: "" };
};

export const getMessages = async (token: string, chatId: string): Promise<IMessage[]> => {
  try {
    const messages = await axios.get(`${baseUrl}/messages/${chatId}`, {
      headers: {
        authorization: `Bearer ${token}` || "",
        'X-Api-Version': '1',
      },
    });
    return messages.data;
  } catch (error) {
    console.log(error);
  }
  return [];
};
