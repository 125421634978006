import { Fab, Action } from 'react-tiny-fab';

import React, { useState } from 'react';
// import { ReactComponent as Icon1 } from './icon1.svg'; // Замените на свою иконку
// import { ReactComponent as Icon2 } from './icon2.svg'; // Замените на свою иконку
// import { ReactComponent as Icon3 } from './icon3.svg'; // Замените на свою иконку

const styles = {
  backgroundColor: '#AE4CFB',
  boxShadow: '0px 4px 8px 0px rgba(174, 76, 251, 0.20)',
  rotate: 'none',
}

const FabComponent = ({ openChat }: { openChat?: () => void }) => {
  const [isHovered, setIsHovered] = useState(false);
  return (
<Fab
            mainButtonStyles={styles}
            style={{
              bottom: 0,
              right: 0,
            }}
            icon={<svg
              xmlns="http://www.w3.org/2000/svg"
              width="28"
              height="28"
              fill="none"
              viewBox="0 0 28 28"
            >
              <path
                fill="#fff"
                fillRule="evenodd"
                d="M4.202 14c0 1.408.297 2.747.832 3.958l-.834 5.84 5.005-1.251A9.753 9.753 0 0014 23.799c5.412 0 9.8-4.387 9.8-9.8 0-5.412-4.388-9.8-9.8-9.8-5.412 0-9.799 4.388-9.799 9.8zm6.27-2.8c-.37 0-.672.278-.672.621 0 .344.301.623.672.623h7.056c.371 0 .672-.279.672-.623 0-.343-.3-.622-.672-.622h-7.056zm0 4.355c-.37 0-.672.278-.672.622s.301.622.672.622h4.116c.371 0 .672-.278.672-.622s-.3-.622-.672-.622h-4.116z"
                clipRule="evenodd"
              ></path>
            </svg>}
            event={"hover"}
            text='Онлайн чат'
            onClick={openChat}
          >
            <Action
              style={styles}
              text="Справка о системе"
              onClick={e => {
                window.open(process.env.REACT_APP_FAQ, "_blank")
              }}
            >
              <svg
      xmlns="http://www.w3.org/2000/svg"
      width="28"
      height="28"
      fill="none"
      viewBox="0 0 28 28"
    >
      <path
        fill="#fff"
        fillRule="evenodd"
        d="M14 23.8c5.413 0 9.8-4.388 9.8-9.8 0-5.413-4.387-9.8-9.8-9.8-5.412 0-9.8 4.387-9.8 9.8 0 5.412 4.388 9.8 9.8 9.8zm-.992-7.83c-.04.412.313.75.741.75a.8.8 0 00.795-.75l.005-.069c.021-.211.103-.442.245-.69.11-.191.257-.366.443-.525.192-.159.391-.317.599-.476.213-.16.41-.334.59-.524.328-.329.588-.697.779-1.104.197-.408.295-.848.295-1.319 0-.386-.063-.754-.188-1.104a2.621 2.621 0 00-.574-.929 2.776 2.776 0 00-1.255-.818 5.018 5.018 0 00-1.582-.246c-.421 0-.84.063-1.255.19a3.097 3.097 0 00-1.107.58c-.344.27-.614.614-.811 1.033a3.707 3.707 0 00-.207.558c-.111.399.233.752.66.752.406 0 .718-.33.856-.7l.003-.007c.082-.222.21-.41.385-.563.175-.17.393-.294.656-.374.268-.08.552-.119.853-.119.317 0 .62.045.91.135.295.09.54.254.738.492.13.138.224.305.278.5.06.191.09.406.09.644 0 .27-.035.527-.106.77-.071.239-.216.477-.435.715a5.07 5.07 0 01-.746.643 8.158 8.158 0 00-.705.549 2.44 2.44 0 00-.566.722c-.175.307-.29.625-.344.953-.016.106-.03.217-.04.33zm-.025 3.116c0 .412.345.747.77.747.426 0 .77-.335.77-.747v-.246a.759.759 0 00-.77-.747.759.759 0 00-.77.747v.246z"
        clipRule="evenodd"
      ></path>
    </svg>
            </Action>
            <Action
              style={styles}
              text="Телеграм"
              onClick={e => {
                window.open(process.env.REACT_APP_TELEGRAM, "_blank")
              }}
            >
                  <svg
      xmlns="http://www.w3.org/2000/svg"
      width="28"
      height="28"
      fill="none"
      viewBox="0 0 28 28"
    >
      <path
        fill="#fff"
        fillRule="evenodd"
        d="M4.34 13.435c6.013-2.569 10.022-4.262 12.028-5.08 5.729-2.336 6.919-2.742 7.695-2.755.17-.003.552.038.799.235.209.166.266.39.293.548.028.157.062.515.035.796-.31 3.197-1.654 10.957-2.337 14.538-.29 1.516-.858 2.024-1.41 2.074-1.197.108-2.107-.776-3.267-1.522-1.816-1.167-2.841-1.893-4.603-3.031-2.037-1.316-.716-2.04.444-3.221.304-.31 5.581-5.016 5.684-5.443.012-.053.024-.252-.096-.357-.121-.105-.3-.07-.428-.04-.182.04-3.082 1.919-8.7 5.637-.823.554-1.569.824-2.237.81-.736-.015-2.153-.408-3.206-.744-1.292-.411-2.318-.629-2.229-1.328.047-.364.558-.737 1.535-1.117z"
        clipRule="evenodd"
      ></path>
    </svg>
            </Action>
          </Fab>
  );
};

export default FabComponent;
