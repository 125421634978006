import React, { FunctionComponent } from "react";

interface ChatMessageProps {
  text: string;
  time: string;
}

const ChatMessage: FunctionComponent<ChatMessageProps> = ({ text, time }) => {
  return (
    <div className="self-stretch flex flex-row items-start justify-end text-left text-text-primary">
      <div className="rounded-surfaces bg-bg-tertiary-subdued shrink-0 flex flex-col items-start justify-center py-[0.38rem] px-[0.75rem] gap-[0.25rem]">
        <div className="relative leading-[1.25rem] font-medium w-[150px] break-all">{text}</div>
        <div className="relative text-[0.75rem] leading-[1rem] font-medium text-text-tertiary">
          {time}
        </div>
      </div>
    </div>
  );
};

export default ChatMessage;
