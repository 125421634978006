import React, { FunctionComponent, useEffect, useRef, useState } from "react";
import Header from "../components/Header";
import FormContainer from "../components/FormContainer";
import Size12StateDefaultBgpri from "../components/Size12StateDefaultBgpri";
import ChatInput from "../components/ChatInput";
import ChatMessage from "../components/ChatMessage"; // Импортируем новый компонент
import FabComponent from "../components/FAB";
import "react-tiny-fab/dist/styles.css";
import { createChat, getChat, getMe, getMessages, register } from "../api/api";
import { io, Socket } from "socket.io-client";
import { DefaultEventsMap } from "@socket.io/component-emitter";
import { IMessage } from "../types/model";
// import { useLocation } from "react-router-dom";

const baseUrl =
  process.env.REACT_APP_URL || "https://chat-api.test-team-flame.ru";

const SupportChat: FunctionComponent<{
  tokenParam?: string;
  script: HTMLOrSVGScriptElement | null;
}> = ({ tokenParam, script }) => {
  const anchor = useRef<HTMLDivElement>(null);
  const [isOpenedChat, setIsOpenedChat] = useState(false);
  const [userId, setUserId] = useState("");
  const [token, setToken] = useState("");
  const [chatId, setChatId] = useState("");
  const [socket, setSocket] = useState<
    Socket<DefaultEventsMap, DefaultEventsMap> | undefined
  >();
  const [messages, setMessages] = useState<IMessage[]>([]);
  const [position, setPosition] = useState<
    { bottom: number | string; left: number | string } | undefined
  >();
  // let { search } = useLocation();

  useEffect(() => {
    init();
  }, []);

  const init = async () => {
    if (tokenParam) {
      const params = new Proxy(
        new URLSearchParams(tokenParam.split("?")[1] + "?" || ""),
        {
          get: (searchParams, prop) => searchParams.get(prop as any),
        }
      );
      const { token, left, bottom } = params as any;
      if (left && bottom) {
        setPosition({
          left: parseInt(left),
          bottom: parseInt(bottom),
        });
        setIsOpenedChat(true);
      }
      if (token) {
        const u = await getMe(token);
        const chat = await getChat(token);
        const m = await getMessages(token, chat._id);
        setUserId(u.id);
        setChatId(chat._id);
        setMessages(m.reverse());
        setToken(token);
      }
    }
  };

  useEffect(() => {
    if (anchor.current) {
      anchor.current.scrollIntoView({
        behavior: "smooth",
        block: "center",
        inline: "center",
      });
    }
  }, [isOpenedChat]);

  const sendMessage = async (message: string) => {
    if (!token) {
      const user = await register();
      if (user) {
        await new Promise((r) => setTimeout(r, 1000));
        const { _id } = await createChat(user.tokens.accessToken.token);
        setToken(user.tokens.accessToken.token);
        setUserId(user.id);
        setChatId(_id);
        const s = io(baseUrl || "", {
          auth: {
            authorization: user.tokens.accessToken.token,
          },
          transports: ["websocket"],
        });
        setSocket(s);
        s.connect();
        s.on("messageToClient", (newMessage: IMessage) => {
          if (newMessage.chat_id === _id) {
            setMessages((prev) => [...prev, newMessage]);
            console.log(messages);
          }
        });
        console.log(_id);
        if (_id) {
          s.emit("sendMessageToService", {
            chat_id: _id,
            sender_id: user.id,
            attachment_id: "",
            message,
          });
        }
      }
    } else {
      if (socket) {
        socket.emit("sendMessageToService", {
          chat_id: chatId,
          sender_id: userId,
          attachment_id: "",
          message,
        });
      }
    }
  };

  useEffect(() => {
    if (token && !socket) {
      const s = io(baseUrl || "", {
        auth: {
          authorization: token,
        },
        transports: ["websocket"],
      });
      setSocket(s);
      s.connect();
      s.on("messageToClient", (newMessage: IMessage) => {
        console.log(newMessage);
        if (newMessage.chat_id === chatId) {
          setMessages((prev) => [...prev, newMessage]);
        }
      });
    }
  }, [token]);

  const close = () => {
    setIsOpenedChat((prev) => !prev);
    if (position && script) {
      script.remove();
      document.getElementById("chat-widget-container")?.remove();
    }
  };

  if (isOpenedChat) {
    return (
      <div
        id="chat-widget"
        className="chat-widget fixed bottom-8 right-8 rounded-[16px] bg-bg-primary shadow-[0px_4px_12px_rgba(46,_54,_62,_0.16)] w-[348px] h-[27.63rem] flex flex-col items-start justify-start text-center text-[0.88rem] text-text-tertiary font-body-12px-16lh-medium"
        style={
          position
            ? {
                left: position.left,
                bottom: position.bottom,
              }
            : {}
        }
      >
        <Header
          title="Чат поддержки"
          actionlose="/actionlose@2x.png"
          typeSecondaryContentTitlePadding="0.75rem 0.75rem 0.75rem 1rem"
          typeSecondaryContentTitleHeight="3rem"
          titleFontSize="1rem"
          titleLineHeight="1.5rem"
          buttonsCompactHeight="1.5rem"
          buttonsCompactPadding="0.25rem"
          actionloseIconWidth="1rem"
          actionloseIconHeight="1rem"
          onClose={close}
        />
        <div className="self-stretch flex-1 rounded-menu-popups overflow-scroll flex flex-row items-end py-[0rem] pr-[0rem] pl-[1rem] gap-[0.25rem] pr-[1rem]">
          {!token ? (
            <div className="flex justify-center items-center h-full w-full">
              <div className="self-stretch flex flex-row items-center justify-center py-[0rem] px-[1rem]">
                <div className="flex-1 relative leading-[1.25rem] font-medium">
                  Опишите подробно, что произошло. Специалист службы поддержки
                  ответит вам в ближайшее время.
                </div>
              </div>
            </div>
          ) : (
            <div className="self-stretch flex-1 flex flex-col items-end py-[0.75rem] px-[0rem] gap-[1rem] overflow-anchor-none">
              {/* <div className="self-stretch flex flex-row items-center justify-center py-[0rem] px-[1rem]">
                <div className="flex-1 relative leading-[1.25rem] font-medium">
                  Сегодня
                </div>
              </div> */}
              {/* Используем ChatMessage для отображения сообщений */}
              {messages &&
                messages.map((message) =>
                  message.sender_id === userId ? (
                    <ChatMessage
                      key={message._id}
                      text={message.message || ""}
                      time={`${new Date(
                        message.createdAt
                      ).getHours()}:${new Date(
                        message.createdAt
                      ).getMinutes()}`}
                    />
                  ) : (
                    <FormContainer
                      key={message._id}
                      text={message.message || ""}
                      time={`${new Date(
                        message.createdAt
                      ).getHours()}:${new Date(
                        message.createdAt
                      ).getMinutes()}`}
                    />
                  )
                )}
              {!messages.find((item) => item.sender_id !== userId) && (
                <div className="self-stretch flex flex-row items-center justify-center py-[0rem] px-[1rem]">
                  <div className="flex-1 relative leading-[1.25rem] font-medium">
                    Пожалуйста, подождите. Сейчас операторы заняты, но скоро
                    кто-нибудь освободится и ответит вам!
                  </div>
                </div>
              )}
              {/* <FormContainer /> */}
              <div ref={anchor} className="anchor overflow-auto h-1" />
            </div>
          )}
          {/* <Size12StateDefaultBgpri
            visible
            size12StateDefaultBgpriWidth="0.75rem"
            size12StateDefaultBgpriPadding="0.25rem 0.25rem 0.25rem 0rem"
            rectangleDivWidth="0.38rem"
            rectangleDivHeight="10.5rem"
          /> */}
        </div>
        <ChatInput
          fillSendSolid="/fill-send-solid@2x.png"
          versionsupportAttachnoSaPadding="0.75rem 1rem 0.75rem 1.5rem"
          versionsupportAttachnoSaGap="1rem"
          messageGap="0.13rem"
          cursorWidth="0.06rem"
          cursorHeight="1.31rem"
          divFontSize="0.88rem"
          divLineHeight="1.25rem"
          fillSendSolidWidth="1.5rem"
          fillSendSolidHeight="1.5rem"
          sendMessage={sendMessage}
        />
      </div>
    );
  }
  return <FabComponent openChat={() => setIsOpenedChat((prev) => !prev)} />;
};

export default SupportChat;
